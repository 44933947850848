import { Inspection } from '../../models/Inspection';

export class AddInspection {
  static readonly type = '[Inspections] Add';
  constructor(public payload: Inspection) { }
}

export class DeleteInspection {
  static readonly type = '[Inspections] delete';
  constructor(public payload: number) { }
}
