import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/guards/auth-guard.service';
import { RoleGuard } from './services/guards/role.guard';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'board',
    loadChildren: () => import('./board/board.module').then( m => m.BoardPageModule),
    canActivate: [AuthGuardService, RoleGuard],
    data: {
      permission: 'Ingresar al tablero'
    }
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'Usuarios',
    loadChildren: () => import('./user/user.module').then( m => m.UserPageModule),
    canActivate: [AuthGuardService, RoleGuard],
    data: {
      permission: 'Ver Usuarios'
    }
  },
  {
    path: 'Bienes',
    loadChildren: () => import('./property/property.module').then(m => m.MemberPageModule),
    canActivate: [AuthGuardService, RoleGuard],
    data: {
      permission: 'Ver Bienes'
    }
  },
  {
    path: 'Acceso/Rol',
    loadChildren: () => import('./acl/role/role.module').then( m => m.RolePageModule),
    canActivate: [AuthGuardService, RoleGuard],
    data: {
      permission: 'Ver Roles'
    }
  },
  {
    path: 'Inspeccion/TipoInspeccion',
    loadChildren: () => import('./inspection/coordination/type-inspection/type-inspection-routing.module')
      .then(m => m.TypeInspectionPageRoutingModule),
    canActivate: [AuthGuardService, RoleGuard],
    data: {
      permission: 'Crear Inspecciones'
    }
  },
  {
    path: 'Inspeccion/Coordinacion',
    loadChildren: () => import('./inspection/coordination/coordination/coordination.module').then(m => m.CoordinationPageModule),
    canActivate: [AuthGuardService, RoleGuard],
    data: {
      permission: 'Crear Inspecciones'
    }
  },
  {
    path: 'Inspeccion/Coordinacion/:id',
    loadChildren: () => import('./inspection/coordination/coordination/coordination.module').then(m => m.CoordinationPageModule),
    canActivate: [AuthGuardService, RoleGuard],
    data: {
      permission: 'Editar Inspecciones'
    }
  },
  {
    path: 'Configuracion/Empresa',
    loadChildren: () => import('./configuration/company/company.module').then( m => m.CompanyPageModule),
    canActivate: [AuthGuardService, RoleGuard],
    data: {
      permission: 'Ver Empresas'
    }
  },
  {
    path: 'Configuracion/Taller',
    loadChildren: () => import('./configuration/workshop/workshop.module').then( m => m.WorkshopPageModule),
    canActivate: [AuthGuardService, RoleGuard],
    data: {
      permission: 'Ver Talleres'
    }
  },
  {
    path: 'manage-customer-modal',
    loadChildren: () => import('./inspection/modals/manage-customer-modal/manage-customer-modal.module').then(
      m => m.ManageCustomerModalPageModule
    )
  },
  {
    path: 'search-customer',
    loadChildren: () => import('./inspection/modals/customer/search-customer/search-customer.module').then( m => m.SearchCustomerPageModule)
  },
  {
    path: 'search-subsidiary',
    loadChildren: () => import('./inspection/modals/search-subsidiary/search-subsidiary.module').then( m => m.SearchSubsidiaryPageModule)
  },
  {
    path: 'search-user',
    loadChildren: () => import('./inspection/modals/search-user/search-user.module').then( m => m.SearchUserPageModule)
  },
  {
    path: 'search-workshop',
    loadChildren: () => import('./inspection/modals/search-workshop/search-workshop.module').then( m => m.SearchWorkshopPageModule)
  },
  {
    path: 'search-car',
    loadChildren: () => import('./inspection/modals/search-car/search-car.module').then( m => m.SearchCarPageModule)
  },
  {
    path: 'add-car',
    loadChildren: () => import('./inspection/modals/add-car/add-car.module').then( m => m.AddCarPageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./inspection/modals/map/map.module').then( m => m.MapPageModule)
  },
  {
    path: 'search-property',
    loadChildren: () => import('./inspection/modals/search-property/search-property.module').then( m => m.SearchPropertyPageModule)
  },
  {
    path: 'search-city',
    loadChildren: () => import('./inspection/modals/search-city/search-city.module').then( m => m.SearchCityPageModule)
  },
  {
    path: 'photographic-inspection',
    loadChildren: () => import('./inspection/process/photographic-inspection/photographic-inspection.module').then(m =>
      m.PhotographicInspectionPageModule
    ),
    canActivate: [AuthGuardService]
  },
  {
    path: 'extra-data',
    loadChildren: () => import('./inspection/process/extra-data/extra-data.module').then( m => m.ExtraDataPageModule)
  },
  {
    path: 'sign',
    loadChildren: () => import('./inspection/process/sign/sign.module').then( m => m.SignPageModule)
  },
  {
    path: 'photos',
    loadChildren: () => import('./inspection/process/photos/photos.module').then( m => m.PhotosPageModule)
  },
  {
    path: 'revision',
    loadChildren: () => import('./inspection/revision/report/report.module').then( m => m.ReportPageModule)
  },
  {
    path: 'well-insured',
    loadChildren: () => import('./inspection/revision/well-insured/well-insured.module').then( m => m.WellInsuredPageModule)
  },
  {
    path: 'analysis',
    loadChildren: () => import('./inspection/revision/analysis/analysis.module').then( m => m.AnalysisPageModule)
  },
  {
    path: 'final',
    loadChildren: () => import('./inspection/revision/final/final.module').then( m => m.FinalPageModule)
  },
  {
    path: 'report-lakam',
    loadChildren: () => import('./formats/inspection/report-lakam/report-lakam.module').then( m => m.ReportLakamPageModule)
  },
  {
    path: 'Proveedor/Proformas',
    loadChildren: () => import('./provider/proposal/proposal.module').then( m => m.ProposalPageModule)
  },
  {
    path: 'Inspeccion/Cotizacion',
    loadChildren: () => import('./inspection/quotation/quotation.module').then( m => m.QuotationPageModule)
  },
  {
    path: 'attachments',
    loadChildren: () => import('./inspection/modals/attachments/attachments.module').then( m => m.AttachmentsPageModule)
  },
  {
    path: 'validate',
    loadChildren: () => import('./validate/validate.module').then( m => m.ValidatePageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
