import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { LoginState } from './state/authenticate/login.state';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private store: Store
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.select(LoginState.token).subscribe(token => {
      if (req.body instanceof FormData) {} else {
        if (!req.headers.has('Content-Type')) {
          req = req.clone({headers: req.headers.set('Content-Type', 'application/json')});
        }
      }

      req = req.clone({headers: req.headers.set('Accept', 'application/json')});
      req = req.clone({headers: req.headers.set('Authorization', 'Bearer ' + token)});
    });
    return next.handle(req);
  }
}
