import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Inspection, InspectionStateModel } from '../../models/Inspection';
import { AddInspection, DeleteInspection } from './inspections.action';

@State<InspectionStateModel>({
  name: 'inspections',
  defaults: {
    inspections: []
  }
})

export class InspectionsState {
  @Selector()
  static getInspections(state: InspectionStateModel) { return state.inspections; }

  @Action(AddInspection)
  add({ getState, setState }: StateContext<InspectionStateModel>, { payload }: AddInspection) {
    const state = getState();
    setState({
      ...state,
      inspections: [...state.inspections]
    });
  }
}
