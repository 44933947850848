import { LoginResponse } from '../../models/LoginInterface';

export enum AuthActionTypes {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LOGIN = '[Auth] Login',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  LOGOUT = '[Auth] Logout',
}

export class Login {
  static readonly type = '[Auth] Login';
  constructor(public payload: { email: string; password: string }) { }
}

export class Logout {
  static readonly type = AuthActionTypes.LOGOUT;
}

export class GetUser {
  static readonly type = '[User] get data';
}

export class SetUser {
  static readonly type = '[User] add data';
  constructor(public payload: LoginResponse) { }
}
