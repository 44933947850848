import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LoginResponse, LoginModel } from '../models/LoginInterface';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public url = environment.url;
  constructor(
    private http: HttpClient,
  ) { }

  login(user: LoginModel) {
    return new Promise(resolve => {
      this.http.post<LoginResponse>(this.url + 'login', user, {}).subscribe(res => {
        if (res.status === 'SUCCESS') {
          resolve(res);
        } else {
          resolve(res);
        }
      });
    });
  }

  async logout() {
    return new Promise(resolve => {
      const token = null;
      resolve(true);
    });
  }

  recovery_password(data){
    return this.http.post<any>(this.url+'recovery_password',data)
  }

  findUser(data){
    return this.http.post<any>(this.url+'findUser',data)
  }

  tokenBrowser(data){
    return this.http.post<any>(this.url+'tokenBrowser',data)
  }

  validateToken(data){
    return this.http.post<any>(this.url+'validateToken',data)
  }
}
