import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ListPermissions, Permission } from '../models/permission';
import { HttpClient } from '@angular/common/http';
import { ToastService } from './toast.service';
import { RoleData } from '../models/role';
import { RoleService } from './role.service';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  public url = environment.url + 'permission';
  private permissions = new BehaviorSubject<Permission[]>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly allData = this.permissions.asObservable();
  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private roleService: RoleService,
    private storage: Storage
  ) {
    this.getPermissions();
  }

  getPermissions() {
    this.http.get<ListPermissions>(this.url, {}).subscribe(response => {
      this.set(response.data);
    });
  }

  set(permissions: Permission[]): void {
    this.permissions.next(permissions);
  }

  activePermission(data: any) {
    return new Promise((resolve: any) => {
      this.http.post<RoleData>(this.url + '/' + 'activePermission', data, {}).subscribe(response => {
        if (response.data.id) {
          this.toastService.presentToast('Datos grabados con éxito.');
          this.roleService.update(response.data, 'update', response.data);
          resolve(data.permission);
        } else {
          this.toastService.presentToast('Ocurrió un error!');
          resolve(null);
        }
      });
    });
  }

  inactivePermission(data: any) {
    return new Promise((resolve: any) => {
      this.http.post<RoleData>(this.url + '/' + 'inactivePermission', data, {}).subscribe(response => {
        if (response.data.id) {
          this.toastService.presentToast('Datos grabados con éxito.');
          this.roleService.update(response.data, 'update', response.data);
          resolve(data.permission);
        } else {
          this.toastService.presentToast('Ocurrió un error!');
          resolve(null);
        }
      });
    });
  }

  get(): Permission[] {
    return this.permissions.getValue();
  }

  updateData(permission: Permission) {
    const findIndex = this.get().findIndex(findData => findData.id === permission.id);
    const updateData = [...this.get()];
    updateData[findIndex] = permission;
    this.permissions.next(updateData);
  }

  // @ts-ignore
  async hasPermission(component): boolean {
    let permissionsData = [];
    const data = await this.storage.get('data');
    permissionsData = data.data.user.roles[0].permissions.map(permission => {
      return permission.name;
    });

    const findPermission = permissionsData.find(permission => permission === component.data.permission);
    if (findPermission) {
      return true;
    }

    this.toastService.presentToast('No tienes acceso a esta sección', 'warning');
    return false;
  }
}
