import { Component, OnInit } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { NavController } from '@ionic/angular';
import { Select, Store, Actions } from '@ngxs/store';
import { Logout, SetUser } from './state/authenticate/login.action';
import { Observable } from 'rxjs';
import { User } from './models/User';
import { LoginState } from './state/authenticate/login.state';
import { LoginService } from './services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{
  @Select(LoginState)
  user$: Observable<User>;
  myUser: User;
  activePageTitle: 'Dashboard';
  activeIndex = 0;
  permissions = [];
  pages = [
    {
      title: 'Dashboard',
      url: 'dashboard',
      icon: 'home',
      permission: null
    },
    {
      title: 'Tablero',
      url: 'board',
      icon: 'list',
      permission: 'Ver Inspecciones',
    },
    {
      title: 'Usuarios',
      url: 'Usuarios',
      icon: 'people',
      permission: 'Ver Usuarios'
    },
    {
      title: 'Bienes',
      url: 'Bienes',
      icon: 'car',
      permission: 'Ver Bienes'
    },
    {
      title: 'Roles',
      url: 'Acceso/Rol',
      icon: 'git-network',
      permission: 'Ver Roles'
    },
    {
      title: 'Empresas',
      url: 'Configuracion/Empresa',
      icon: 'business',
      permission: 'Ver Empresas'
    },
    {
      title: 'Talleres',
      url: 'Configuracion/Taller',
      icon: 'construct',
      permission: 'Ver Talleres'
    },
    {
      title: 'Coordinar Inspección',
      url: 'Inspeccion/TipoInspeccion',
      icon: 'search',
      permission: 'Crear Inspecciones'
    },
    {
      title: 'Proformas',
      url: 'Proveedor/Proformas',
      icon: 'document',
      permission: null
    },
  ];

  constructor(
    private storage: Storage,
    private store: Store,
    private actions: Actions,
    private navController: NavController,
    private loginService: LoginService
  ) {}

  async ngOnInit() {
    this.loadInitialConfiguration();
  }

  async loadInitialConfiguration() {
    let browser = localStorage.getItem('saveBrowser');

    if (browser) {
     this.navController.navigateRoot(['login']);
    }

    await this.storage.create();
    this.storage.get('data').then(data => {
      if (data !== null) {
        this.myUser = data.data.user;
        this.permissions = this.myUser.roles[0].permissions.map(permission => permission.name);
        this.store.dispatch(new SetUser(data));
      }else{
        this.user$.subscribe({
          next:(res)=>{
            this.myUser = res;
            if (res.roles) {
              this.permissions = this.myUser.roles[0].permissions.map(permission => permission.name);
            }
          }
        });
      }
    });
  }

  logout() {
    this.store.dispatch(new Logout());
    setTimeout(() => {
      window.location.reload();
    }, 1500);
  }

  inRole(page: any) {
    if (page.permission === null) { return true; }
    if(this.permissions){
      return this.permissions.includes(page.permission);
    }else{
      return false;
    }
  }

  activatedIndex(index) {
    this.activeIndex = index;
  }

  getRole(user){
    if(user?.roles?.length > 0){
     return user.roles[0].name
    }else{
      return 'Sin rol'
    }
  }
}
