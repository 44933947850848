// import * as auth from './login.reducer';
import { User } from '../../models/User';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Login, Logout, SetUser } from './login.action';
import { LoginService } from '../../services/login.service';
import { LoginResponse } from '../../models/LoginInterface';
import { ToastService } from '../../services/toast.service';
import { Storage } from '@ionic/storage';
import { NavController, MenuController } from '@ionic/angular';
import { Router } from '@angular/router';

@State<User>({
  name: 'auth',
  defaults: {
    token: null
  }
})

@Injectable()
export class LoginState {
  constructor(
    private loginService: LoginService,
    private toastService: ToastService,
    private storage: Storage,
    private navController: NavController,
    private MenuController:MenuController,
    private router:Router
  ) { }

  @Action(Login)
  login({ patchState }: StateContext<User>, { payload }: Login) {
    return this.loginService.login(payload).then((res: LoginResponse) => {
      if (res.status === 'SUCCESS') {
        this.storage.set('data', res);
        patchState(res.data.user);
        // @ts-ignore
        patchState({token: res.data.access_token});

        let browser = localStorage.getItem('saveBrowser');
        if (browser !== undefined && browser !== null) {
          this.navController.navigateRoot(['dashboard']);
        }else{
          localStorage.setItem('user',JSON.stringify(res.data.user))
          this.loginService.tokenBrowser({id:res.data.user.id}).subscribe({
            next:(resp)=>{
              if (resp.status) {
                this.router.navigate(['validate/guardar_navegador'])
              }else{
                this.toastService.presentToast("Ocurrio un error intente nuevamente")
              }
            }
          })
        }
      }else {
        this.toastService.presentToast('Usuario o clave incorrectos');
      }
    });
  }

  @Action(SetUser)
  setUser({patchState}: StateContext<User>, { payload }: SetUser) {
    patchState(payload.data.user);
    // @ts-ignore
    patchState({token: payload.data.access_token});
  }

  @Action(Logout)
  logout({ patchState }: StateContext<User>, { payload }: Login) {
    patchState({token: null});
    patchState(null);
    this.storage.set('data', null);
    localStorage.removeItem('saveBrowser');
    localStorage.removeItem('user');
    localStorage.removeItem('auth.token');
    localStorage.clear();
    // return this.loginService.logout();
    this.MenuController.close();
    this.MenuController.enable(false);
  }

  @Selector()
  static isAuthenticated(state: User) {
    return !!state.token;
  }

  @Selector()
  static token(state: User): string | null {
    return state.token;
  }
}
