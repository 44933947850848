import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ListRoles } from '../models/User';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from './toast.service';
import { Property } from '../models/Property';
import { Role } from '../models/role';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  public url = environment.url + 'role';
  private roles = new BehaviorSubject<Role[]>([]);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  readonly allData = this.roles;
  constructor(
    private http: HttpClient,
    private toastService: ToastService
  ) { }

  getRoles() {
    this.http.get<ListRoles>(this.url, {}).subscribe(response => {
      this.set(response.data);
    });
  }

  saveData(role: Role) {
    return new Promise<Role>(resolve => {
      this.http.post<Role>(this.url, role, {}).subscribe(response => {
        if (response.id) {
          this.add(response);
          this.toastService.presentToast('Datos grabados con éxito.');
          resolve(response);
        } else {
          this.toastService.presentToast('Ocurrió un error!');
          resolve(null);
        }
      });
    });
  }

  async updateData(role: Role) {
    return new Promise<Role>(resolve => {
      this.http.patch<Role>(this.url + '/' + role.id, role, {}).subscribe(response => {
        if (response.id) {
          this.update(role, 'update', response);
          this.toastService.presentToast('Datos actualizados correctamente.');
          resolve(response);
        } else {
          this.toastService.presentToast('Ocurrió un error!');
        }
      });
    });
  }

  async deleteData(role: Role) {
    return new Promise<Role>(resolve => {
      this.http.delete<Role>(this.url + '/' + role.id).subscribe(response => {
        if (response.id) {
          this.update(role, 'delete');
          resolve(response);
          this.toastService.presentToast('Registro eliminado con éxto.');
        } else {
          this.toastService.presentToast('Ocurrió un error');
          resolve(null);
        }
      });
    });
  }

  getById(id: number) {
    return this.get().find(role => role.id === id);
  }

  get(): Role[] {
    return this.roles.getValue();
  }

  set(roles: Role[]): void {
    this.roles.next(roles);
  }

  add(role: Role) {
    const roles = [...this.get(), role];
    this.set(roles);
  }

  update(role: Role, type: string, data: Role = null) {
    const findIndex = this.get().findIndex(find => find.id === role.id);
    const updateData = [...this.get()];
    if (type === 'delete') {
      updateData.splice(findIndex, 1);
    } else {
      updateData[findIndex] = data;
    }
    this.roles.next(updateData);
  }
}
