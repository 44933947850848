import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { BoardPipe } from './board.pipe';
import { InspectionsState } from './state/inspection/inspections.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { LoginState } from './state/authenticate/login.state';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { HttpConfigInterceptor } from './http-config.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { File } from '@ionic-native/file/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { QuillModule, QuillConfig } from 'ngx-quill';
import { TooltipsModule } from 'ionic4-tooltips';
import { TooltipModule } from 'ng2-tooltip-directive';

import quillBetterTable from 'quill-better-table';
import * as Quill from 'quill';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';

const maskConfig: Partial<IConfig> = {
  validation: false,
  showMaskTyped : true,
  dropSpecialCharacters: false
};

Quill.register(
  {
    'modules/better-table': quillBetterTable,
  },
  true
);


const quillConfig: QuillConfig = {
  modules: {
    table: true, // disable table module
    'better-table': {
      operationMenu: {
        items: {
          unmergeCells: {
            text: 'Another unmerge cells name'
          }
        },
        color: {
          colors: ['#eeffff', '#768fff', '#63ccff', '#9be7ff', '#82e9de', '#d7ffd9'], // colors in operationMenu
          text: 'Colores de fondo' // subtitle
        }
      }
    },
    keyboard: {
      bindings: quillBetterTable.keyboardBindings
    }
  }
};

@NgModule({
  declarations: [
    AppComponent,
    BoardPipe,
  ],
  entryComponents: [],
  imports: [
    RxReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfig),
    BrowserAnimationsModule,
    NgxChartsModule,
    HttpClientModule, BrowserModule, IonicModule.forRoot(), AppRoutingModule, ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    IonicStorageModule.forRoot(),
    NgxsModule.forRoot([InspectionsState, LoginState], {
      developmentMode: !environment.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production
    }),
    NgxsStoragePluginModule.forRoot({
      key: 'auth.token'
    }),
    QuillModule.forRoot(quillConfig),
    TooltipsModule.forRoot(),
    TooltipModule
  ],
  providers: [
    File,
    FileOpener,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true},

  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
