import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import { Store } from '@ngxs/store';
import { LoginState } from '../../state/authenticate/login.state';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private router: Router,
    private store: Store
  ) { }

  canActivate(): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.store.select(LoginState.isAuthenticated).subscribe(token => {
        if (token) {
          resolve(true);
        } else {
          this.router.navigate(['/login']);
          resolve(false);
        }
      });
    });
  }
}
