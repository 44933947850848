import { Injectable } from '@angular/core';
import { LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  isLoading = false;
  constructor(
    private toastController: ToastController,
    private loadingController: LoadingController
  ) { }

  async presentToast(message, color = 'dark') {
    const toast = await this.toastController.create({
      message,
      color,
      duration: 2000,
    });
    await toast.present();
  }

  async loadingContent(message) {
    this.isLoading = true;
    return await this.loadingController.create({
      spinner: 'circular',
      message,
      translucent: true,
      cssClass: 'custom-class custom-loading',
      backdropDismiss: false
    }).then(a => {
      a.present().then(() => {
        if (!this.isLoading) {
          a.dismiss().then(() => {});
        }
      });
    });
  }

  async dismissLoading() {
    this.isLoading = false;
    return await this.loadingController.dismiss().then(() => {});
  }
}
